const Cookies = require('js-cookie');
const disableGA = () => {
    // Remove the default tracker.
    if (window.ga) window.ga('remove');
    // Remove the default cookies
    // _ga is used to distinguish users.
    const domain = document.domain.split("www.").pop();
    Cookies.remove('_ga', { path: '/', domain: domain });
    // _gid is used to distinguish users.
    Cookies.remove('_gid', { path: '/', domain: domain });
    // _gat is used to throttle request rate.
    //Cookies.remove('_gat', { path: '/', domain: document.domain });
    const allCookies = Cookies.get();
    const getAllKeys = Object.keys(allCookies);
    // looping through first object 
    getAllKeys.forEach(function(keyName) {
      // using index of to check if the object key name have a matched string
      if (keyName.indexOf('_gat') !== -1) {
        Cookies.remove(keyName, { path: '/', domain: domain });
      }
    });
};

const gaCookiesAllowed = () => {
    const OptanonConsentCookie = Cookies.get('OptanonConsent');
    if (OptanonConsentCookie == null) {
        return false;
    }
    const urlSearchParams = new URLSearchParams(OptanonConsentCookie);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params.hasOwnProperty('groups')) {
        return false;
    }
    const groups = params.groups;
    
    return groups.indexOf('2:1') !== -1;
};

/**
 * Obsługa zgód OneTrust do GA
 */
$(function() {
    if (!gaCookiesAllowed()) {
        disableGA();
    }
    
    $(document).on('click', '.save-preference-btn-handler', function() {
        if (!gaCookiesAllowed()) {
            disableGA();
        }
    });
});
