$(function () {
    $('.report-download-link').on('click', function (e) {
        e.preventDefault();
        let reportForm = $(this).parent().find(".form-report-download");
        reportForm.submit();
    });
    
    $(document).on('click', '.report-download-from-queue-link', function (e) {
        e.preventDefault();
        let reportForm = $(this).parent().find(".form-report-download-from-queue");
        reportForm.submit();
    });
});