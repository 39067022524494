$(function() {
    $('.page-coupon-details input[name="expire_extension_days"], .page-coupon-details select[name="expire_extension_days"]').on('change', function() {
        if ($(this).val() == -1) {
            $('.coupons-other-days-section').fadeIn();
        } else {
            $('.coupons-other-days-section').fadeOut();
        }
    });
    
    /**
     * Rezygnacja z wprowadzenia kodu
     */
    $('.btn-discard-code').on('click', function (e) {
        e.preventDefault();
        $('#modalCodeSubmit').modal('hide');
    });
    
    /**
     * Wprowadzenie kodu
     */
    $('.btn-submit-code').on('click', function (e) {
        e.preventDefault();
        let code = $('.form-code input[name="code"]').val();
        var formData = new FormData();
        formData.append("code", code);
        $.ajax({
            type: "post",
            data: formData, 
            dataType: "json",
            url: "/retailer/codes-check",
            contentType: false,
            processData: false,
            success: function (data) {
                let codeInput = $('.form-code input[name="code"]');
                if (codeInput.hasClass('is-invalid')) {
                    codeInput.removeClass('is-invalid');
                    let invalidFeedback = codeInput.next();
                    invalidFeedback.html();
                }
                if (data.hasOwnProperty('couponData') && data.couponData.hasOwnProperty('id')) {
                    let codeId = data.couponData.id;
                    let codeValue = data.couponData.value;
                    let has_services = data.couponData.has_services;
                    $('input[name="code_id"]').val(codeId);
                    $('.code-value').html(codeValue + ' ZŁ');
                    $('input[name="is_servicable"]').val(has_services);
                    $('.product-section').toggle(!has_services);
                    $('.service-section').toggle(has_services);
                    $('#modalCodeSubmit').modal('show');
                }
            },
            error: function (jqXHR) {
                let codeInput = $('.form-code input[name="code"]');
                if (!codeInput.hasClass('is-invalid')) {
                    codeInput.addClass('is-invalid');
                }
                let invalidFeedback = codeInput.next();
                
                let response = jqXHR.responseJSON;
                if (response.hasOwnProperty('errors') && response.errors.hasOwnProperty('code')) {
                    invalidFeedback.html('<strong>' + response.errors.code[0] + '</strong>');
                } else {
                    invalidFeedback.html('<strong>' + 'Wystąpił nieoczekiwany błąd' + '</strong>');
                }
            }
        });
    });
    
    /**
     * Realizacja kodu
     */
    $('.btn-realize-code').on('click', function (e) {
        e.preventDefault();
        let productId = $('.form-realize-code select[name="product_id"]').val();
        let serviceId = $('.form-realize-code select[name="service_id"]').val();
        let codeId = $('.form-realize-code input[name="code_id"]').val();
        let isServicable = JSON.parse($('input[name="is_servicable"]').val());
        
        let data = isServicable ? {
            service_id: serviceId
        } : {
            product_id: productId
        };
        
        $.ajax({
            type: "put",
            data: JSON.stringify(data), 
            dataType: "json",
            url: "/retailer/codes/" + codeId,
            contentType: "application/json",
            processData: false,
            success: function (data) {
                if (data.hasOwnProperty('result') && data.result == true) {
                    $('#modalCodeSubmit').modal('hide');
                    let productSelect = $('.form-realize-code select[name="product_id"]');
                    if (productSelect.hasClass('is-invalid')) {
                        productSelect.removeClass('is-invalid');
                    }
                
                    let serviceSelect = $('.form-realize-code select[name="service_id"]');
                    if (serviceSelect.hasClass('is-invalid')) {
                        serviceSelect.removeClass('is-invalid');
                    }
                    
                    $('.toast.toast-code-realization').toast('show');
                    
                    let readyToSettle = parseInt($('.my-codes .number').text());
                    $('.my-codes .number').html(readyToSettle + 1);
                } else {
                    let productSelect = $('.form-realize-code select[name="product_id"]');
                    if (!productSelect.hasClass('is-invalid')) {
                        productSelect.addClass('is-invalid');
                    }
                
                    let serviceSelect = $('.form-realize-code select[name="service_id"]');
                    if (!serviceSelect.hasClass('is-invalid')) {
                        serviceSelect.addClass('is-invalid');
                    }
                
                    let invalidFeedbackProduct = productSelect.closest('.row').find('.invalid-feedback');
                    let invalidFeedbackService = serviceSelect.closest('.row').find('.invalid-feedback');

                    invalidFeedbackProduct.html('<strong>' + 'Wystąpił błąd podczas zapisywania danych' + '</strong>');
                    invalidFeedbackService.html('<strong>' + 'Wystąpił błąd podczas zapisywania danych' + '</strong>');
                }
            },
            error: function (jqXHR) {
                let productSelect = $('.form-realize-code select[name="product_id"]');
                if (!productSelect.hasClass('is-invalid')) {
                    productSelect.addClass('is-invalid');
                }
                
                let serviceSelect = $('.form-realize-code select[name="service_id"]');
                if (!serviceSelect.hasClass('is-invalid')) {
                    serviceSelect.addClass('is-invalid');
                }
                
                let invalidFeedbackProduct = productSelect.closest('.row').find('.invalid-feedback');
                let invalidFeedbackService = serviceSelect.closest('.row').find('.invalid-feedback');
                
                let response = jqXHR.responseJSON;
                if (response.hasOwnProperty('errors') && response.errors.hasOwnProperty('product_id')) {
                    invalidFeedbackProduct.html('<strong>' + response.errors.product_id[0] + '</strong>');
                } else if (response.hasOwnProperty('errors') && response.errors.hasOwnProperty('service_id')) {
                    invalidFeedbackService.html('<strong>' + response.errors.service_id[0] + '</strong>');
                } else {
                    invalidFeedbackProduct.html('<strong>' + 'Wystąpił nieoczekiwany błąd' + '</strong>');
                    invalidFeedbackService.html('<strong>' + 'Wystąpił nieoczekiwany błąd' + '</strong>');
                }
            }
        });
    });
    
    /**
     * Inicjalizacja select2 po wyświetleniu modala
     */
    $('#modalCodeSubmit').on('show.bs.modal', function () {
        let codeId = $('input[name="code_id"]').val();
        let isServicable = JSON.parse($('input[name="is_servicable"]').val());
        let selector = isServicable ? '.select2-service' : '.select2-product';
        let url = isServicable ? "/retailer/codes-services/" + codeId : "/retailer/codes-products/" + codeId;
        
        $('.select2-service').prop('disabled', !isServicable);
        $('.select2-product').prop('disabled', isServicable);
        
        $(selector).select2({
            ajax: {
                url: url,
                type: "post",
                dataType: "json",
                delay: 250,
                data: function (params) {
                    return {
                        search: params.term
                    };
                },
                processResults: function (response) {
                    return {
                        results: response
                    };
                },
                cache: true
            },
            language: {
                errorLoading: function () {
                    return 'Nie udało się wczytać wyników.';
                },
                noResults: function() {
                    return "Nie znaleziono wyników.";
                },
                searching: function() {
                    return "Wyszukiwanie...";
                }
            },
            theme: 'bootstrap4',
            width: '100%',
            placeholder: "Wybierz lub wpisz frazę:"
        });
        
        $(selector).empty().trigger('change');
    });
    
    $('.btn-submit-check-code').on('click', function (e) {
        e.preventDefault();
        $('#coupon-check-info').html('').addClass("d-none");
        let code = $('.form-check-code input[name="code"]').val();
        var formData = new FormData();
        formData.append("code", code);
        $.ajax({
            type: "post",
            data: formData, 
            dataType: "json",
            url: "/retailer/codes-check2",
            contentType: false,
            processData: false,
            success: function (data) {
                let codeInput = $('.form-check-code input[name="code"]');
                if (codeInput.hasClass('is-invalid')) {
                    codeInput.removeClass('is-invalid');
                    let invalidFeedback = codeInput.next();
                    invalidFeedback.html();
                }
                if (data.hasOwnProperty('couponData')) {
                    $('#coupon-check-info').html(data.couponData).removeClass("d-none");
                }
            },
            error: function (jqXHR) {
                let codeInput = $('.form-check-code input[name="code"]');
                if (!codeInput.hasClass('is-invalid')) {
                    codeInput.addClass('is-invalid');
                }
                let invalidFeedback = codeInput.next();
                
                let response = jqXHR.responseJSON;
                if (response.hasOwnProperty('errors') && response.errors.hasOwnProperty('code')) {
                    invalidFeedback.html('<strong>' + response.errors.code[0] + '</strong>');
                } else {
                    invalidFeedback.html('<strong>' + 'Wystąpił nieoczekiwany błąd' + '</strong>');
                }
            }
        });
    });
});
