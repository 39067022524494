$(function() {
    $('.btn-edit-comment').on('click', function(e) {
        e.preventDefault();
        var baseUrl = window.location.origin;
        var commentId = $(this).data('commentId');
        var commentContent = $(this).data('commentContent');
        var textarea = $('.form-edit-comment textarea');
        var updateCommentUrl = baseUrl + '/administrator/comments/' + commentId;
        
        $('.form-edit-comment').attr('action', updateCommentUrl);
        textarea.val(commentContent);
        $('.form-create-comment').hide();
        $('.form-edit-comment').show();
    });
    
    $('.btn-comment-back').on('click', function(e) {
        e.preventDefault();
        $('.form-create-comment').show();
        $('.form-edit-comment').hide();
    });
    
    $('.btn-remove-comment').on('click', function(e) {
        e.preventDefault();
        if(confirm('Czy na pewno chcesz usunąć komentarz?')) {
            var form = $(this).siblings('.form-delete-comment');
            form.submit();
        }
    });
});
