$(function() {


    /**
     * Menu główne
     */
    $('.administrator .menu').hover(function() {
        $('.administrator .menu').addClass('on');
    }, function() {
        $('.administrator .menu').removeClass('on');
    });

    /**
     * Maska dla inputów
     */
    if ($('#bank_account_number').length) {

        $('#bank_account_number').mask('00 0000 0000 0000 0000 0000 0000', {reverse: true});
    }
});
