$(function() {
    var $remaining = $('#sms-char-counter'),
        $messages = $('#sms-counter');
    if ($remaining.length != 0 && $messages.length != 0) {
        var message = $('#content').val();
        var chars = 0;
        for (var i = 0, len = message.length; i < len; i++) {
            chars += message.charCodeAt(i) < 0x10000 ? 1 : 2;
        }
        if (chars == 0) {
            remaining = 160;
            messages = 1;
        }
        var messages = Math.ceil(chars / 160);
        var remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

        $remaining.text(chars);
        $messages.text(messages);
    }
    
    $('#content').on('keyup', function() {
        var message = $(this).val();
        var chars = 0;
        for (var i = 0, len = message.length; i < len; i++) {
            chars += message.charCodeAt(i) < 0x10000 ? 1 : 2;
        }
        if (chars == 0) {
            remaining = 160;
            messages = 1;
        }
        var messages = Math.ceil(chars / 160);
        var remaining = messages * 160 - (chars % (messages * 160) || messages * 160);

        $remaining.text(chars);
        $messages.text(messages);
    });

    $('.page-communication .custom-file-input').on('change', function() {
        //get the file name
        var file = $(this).get(0).files.item(0);
        var formData = new FormData();
        // add assoc key values, this will be posts values
        formData.append("file", file, file.name);
        $.ajax({
            type: "post",
            data: formData, 
            dataType: "json",
            url: "/administrator/communication/upload",
            contentType: false,
            processData: false,
            success: function (data) {
                $('.recipients-file-count').text(data);
                //replace label content
                $('.page-communication .custom-file-input').next('.custom-file-label').html(file.name);
            }
        });
        
    });
    
    if ($('input[name="recipients_from_file"]').is(':checked')) {
        $('.recipients-file-section').show();
    } else {
        $('.recipients-file-section').hide();
    }
    
    if ($('input[name="recipients_from_campaign"]').is(':checked')) {
        $('.campaign-section').show();
    } else {
        $('.campaign-section').hide();
    }
    
    $('input[name="recipients_from_file"]').on('change', function() {
        $('.recipients-file-section').toggle($(this).is(':checked'));
    });
    
    $('input[name="recipients_from_campaign"]').on('change', function() {
        $('.campaign-section').toggle($(this).is(':checked'));
    });
    
    let campaignName = $('select[name="campaign_id"] option:selected').text();
    $('.communication-races-section').toggle(campaignName.includes("Odbierz prezent") || campaignName.includes("Kupon VET_2022"));
    $('.communication-petage-section').toggle(campaignName.includes("Odbierz prezent"));
    $('.communication-fooder-section').toggle(campaignName.includes("Odbierz prezent"));
    
    $('select[name="campaign_id"]').on('change', function(e) {
        let campaignName = $('select[name="campaign_id"] option:selected').text();
        $('.communication-races-section').toggle(campaignName.includes("Odbierz prezent") || campaignName.includes("Kupon VET_2022"));
        $('.communication-petage-section').toggle(campaignName.includes("Odbierz prezent"));
        $('.communication-fooder-section').toggle(campaignName.includes("Odbierz prezent"));
    });
});
