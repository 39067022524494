$(function() {
    function updateRegulationsLink(department, payerId = null, recipientId = null)
    {
        var formData = new FormData();
        formData.append("department", department);
        formData.append("payerId", payerId);
        formData.append("recipientId", recipientId);
        
        $.ajax({
            type: "post",
            data: formData, 
            dataType: "json",
            url: "/regulations-department",
            contentType: false,
            processData: false,
            success: function (department) {
                let baseUrl = window.location.origin;
                let regulationsUrl = baseUrl + '/regulations?department=' + department;
                $('.regulations-link').attr('href', regulationsUrl);
            }
        });
    }
    
    $(".register-form input[name='payer_id'], .register-form input[name='recipient_id']").on("input", function() {
        let department = $('input[name="department"]').val();
        let payerId = $(".register-form input[name='payer_id']").val();
        let recipientId = $(".register-form input[name='recipient_id']").val();
        
        updateRegulationsLink(department, payerId, recipientId);
    });
    /**
     * Wybór Lecznica weterynaryjna lub Sklep zoologiczny
     */
    $('.register-tile').on("click", function() {
        let department = $(this).data("department");
        let payerId = $(".register-form input[name='payer_id']").val();
        let recipientId = $(".register-form input[name='recipient_id']").val();
        
        updateRegulationsLink(department, payerId, recipientId);
        
        $('.register-first-step').fadeOut();
        
        if (department === 'pos') {
            $('.register-vet-roles-section').fadeOut();
            $('.register-pos-roles-section').fadeIn();
        } else {
            $('.register-vet-roles-section').fadeIn();
            $('.register-pos-roles-section').fadeOut();
        }
        
        $('input[name="department"]').val(department);
        $('.register-form-section').fadeIn();
    });
    
    /**
     * Powrót do wyboru działu
     */
    $('.btn-register-back').on("click", function(e) {
        e.preventDefault();
        $('.register-form-section').fadeOut();
        $('.register-form-section').find('.is-invalid, .text-danger').removeClass('is-invalid text-danger');
        $('.register-first-step').fadeIn();
    });
    
    /**
     * Zaznaczenie wszystkich zgód
     */
    $('#all_consents').on("change", function() {
        $("input[name='consent_participate']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_sms']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_sms_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_email']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_email_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_phone']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='consent_phone_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
        
        $("input[name='regulations_consent']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_sms']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_mail']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_phone']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_sms_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_phone_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
        $("input[name='agreement_mail_mars']:not(:disabled)").prop('checked', $(this).is(':checked'));
    });
    
    /**
     * Znacznik wymagalności zgód RC
     */
    $(".register-form input[name='role_id']").on("change", function() {
        if ($(this).val() == 5 || $(this).val() == 6) {
            $('.rc-consent').text('**');
        } else {
            $('.rc-consent').text('***');
        }
    });
    
    if ($(".register-form input[name='role_id']").val() == 5 || $(".register-form input[name='role_id']").val() == 6) {
        $('.rc-consent').text('**');
    } else {
        $('.rc-consent').text('***');
    }
});
