require('./bootstrap');
$.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});

require('jquery-mask-plugin');
require('select2');

require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-responsive');
require('datatables.net-responsive-bs4');
require('datatables.net-buttons-bs4');

require('summernote/dist/summernote-bs4.css');
require('./summernote-bs4-fixed.js');
require('summernote/dist/lang/summernote-pl-PL.js');

require('./registration');
require('./administrator');
require('./comments');
require('./coupons');
require('./communication');
require('./files');
require('./users');
require('./reports');
require('./one-trust');

import AirDatepicker from 'air-datepicker';
import localePl from 'air-datepicker/locale/pl';
import 'air-datepicker/air-datepicker.css';

$(function () {
    var windowsize = $(window).width();
    /**
     * Inicjalizacja select2
     */
    $('.select2').select2({
        language: 'pl',
        theme: 'bootstrap4',
        width: '100%'
    });

    /**
     * Inicjalizacja air-datepicker
     */
    $('.air-datepicker-field').each(function () {
        let options = {
            locale: localePl,
            autoClose: true,
            buttons: ['clear'],
            isMobile: windowsize <= 768
        };

        new AirDatepicker(this, options);
    });

    $('.wyswig').each(function () {
        var options = {
            lang: 'pl-PL',
            minHeight: 300,
            codeviewFilter: false,
            codeviewFilterRegex: new RegExp($.summernote.options.codeviewFilterRegex.source + '|<.*?( on\\w+?=.*?)>', 'gi'),
            codeviewIframeFilter: true,
            dialogsFade: true,
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'hr']],
                ['view', ['fullscreen', 'help']]
            ]
        };
        $('.wyswig').summernote(options);
    });

    /**
     * Wyłączenie możliwości edycji summernote
     */
    $('.wyswig.wyswig-disabled').summernote('disable');

    /**
     * Menu mobilne administrator/detalista
     */
    $('.mobile-menu-btn').on('click', function () {
        if ($('.mobile-menu').hasClass('on')) {
            $('.mobile-menu').removeClass('on');
        } else {
            $('.mobile-menu').addClass('on');
        }
    });

    /**
     * Zapobieganie wielokrotnym kliknięciu przycisku typu submit
     */
    $('form:not(.no-double-click-prevention) button[type="submit"]').on('click', function (e) {
        e.preventDefault();
        let form = $(this).closest('form');
        if (form.length != 0) {
            form.submit();
            $(this).prop('disabled', true);
            $(this).append('<i class="fas fa-spinner fa-pulse"></i>');
        }
    });

    $('.form-confirm').on('submit', function (e) {
        if (!confirm('Czy na pewno?')) {
            e.preventDefault();
            return false;
        }

        $(this).find('button[type=submit]').prop('disabled', true);
        $(this).find('button[type=submit]').append('<i class="fas fa-spinner fa-pulse"></i>');
    });

    /**
     * Inicjalizacja alertów bootstrap toast
     */
    $('.toast').toast({
        delay: 5000
    });
});
